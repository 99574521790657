import { ProviderService } from 'shared/models/providerService.model';
import { IBasketCounter } from 'mp-models/basketCounter.model';
import { clientSentry } from 'shared/utils/sentry/clientSentry.util';
import { OciOrdersApiService } from '../api/ociOrdersApi.service';
import { useUserStore } from 'store/user.store';

export class OciBasketCounterService extends ProviderService implements IBasketCounter {
  public counter = ref(0);
  public basisId = ref();
  public static readonly serviceName = 'basketCount';

  public shortedCount = computed(
    () => {
      if (!this.counter.value) {
        return '';
      }
      return this.counter.value > 99 ? '99+' : String(this.counter.value);
    },
  );

  constructor(
    private readonly userStore = useUserStore(),
  ) {
    super();
  }

  public async loadCounter(): Promise<void> {
    if (!this.userStore.sessionId) {
      return;
    }

    try {
      this.counter.value = (await OciOrdersApiService.getBasketItemsCounts(this.userStore.sessionId))?.count;
    } catch (error) {
      clientSentry.captureServiceException(
        error,
        'oci-basket-counter-service',
      );
    }
  }

  public clear(): void {
    this.counter.value = 0;
  }

  public setCount(count: number): void {
    this.counter.value = count;
  }
}
